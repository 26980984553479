import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet anual de fins a 8 cm de llarg i 1,5 cm de gruix, resupinat i disposat en forma més o manco allargada. Per una part està aferrat al tronc o branqueta on viu i per la part exterior és més prim, lleugerament alçat i de color negrós. El color és de blanquinós a més groguenc amb l’edat i finalment groc negrós. L’himeni té forma de tubs disposats en una capa, amb porus de rodons a angulars. Les espores blanquinoses en massa, cilíndriques, llises, de 10-17 x 3,5-6 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      